.ugc_ask_form_wrap {
  margin: 20px 0px 100px 0px;
}

.ugc_ask_form_wrap .form-control:disabled{
  background-color: transparent;
  color: var(--text-white);
}

.ugc_ask_input_wrap {
  margin: 16px 0px;
}

.ugc_ask_input_wrap input,
.ugc_ask_input_wrap select,
.ugc_ask_input_wrap textarea {
  margin: 12px 0px 0px 0px;
}

.ugc_ask_input_wrap textarea,
.ugc_ask_input_wrap textarea:focus {
  font-family: var(--font-family-sans);
  line-height: 16.34px;
  color: var(--text-dark);
}

.ugc_ask_input_wrap input,
.ugc_ask_input_wrap input:focus,
.ugc_ask_input_wrap input::placeholder {
  color: var(--text-white);
  font-family: var(--font-family-sans);
  line-height: 16.34px;
}

.ugc_ask_input_wrap h2 {
  color: var(--text-info);
  font-family: var(--font-family-sans);
  font-weight: var(--font-weight-700);
  line-height: 22px;
  font-size: 16px;
}

.ugc_ask_input_wrap textarea,
.ugc_ask_input_wrap textarea:focus {
  background-color: var(--bg-white);
}

.ugc_ask_input_radio_sec {
  display: flex;
  gap: 10px;
  align-items: end;
}

.ugc_ask_input_radio_sec input[type="radio"] {
  background-color: transparent;
}

.ugc_ask_input_option_sec {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  align-items: center;
}

.ugc_ask_input_option_sec div {
  width: 100%;
  max-width: 100%;
}

.ugc_ask_input_radio_sec label span {
  font-size: 12px;
}

.ugc_ask_input_end_time span:nth-child(3) {
  /* background: var(--text-linear-gradient);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text; */
  font-size: 10px;
  font-family: var(--font-family-sans);
  line-height: 13.62px;
  color: var(--text-glass);
}

.ugc_ask_form_wrap button[type="submit"] {
  width: 100%;
  max-width: 328px;
}

.ugc_ask_input_wrap select {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.ugc_ask_input_wrap_option_create_icon {
  background: var(--bg-linear-gradient-primary);
  width: 100%;
  max-width: 30px;
  height: 30px;
  border-radius: 20px;
  text-align: center;
  cursor: pointer;
  display: grid;
  justify-content: center;
  margin: auto;
  align-items: center;
}

.ugc_ask_input_wrap select.arrow {
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none"%3E%3Ccircle cx="9" cy="9" r="9" fill="%23D9D9D9" fill-opacity="0.4"%3E%3C/circle%3E%3Cpath d="M9 13.5L5.10289 6.75L12.8971 6.75L9 13.5Z" fill="%23262626"%3E%3C/path%3E%3C/svg%3E');
  background-repeat: no-repeat;
  background-size: 18px;
  background-position: calc(100% - 10px) center;
}

input[type="date"]::-webkit-calendar-picker-indicator,
input[type="time"]::-webkit-calendar-picker-indicator {
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none"%3E%3Ccircle cx="9" cy="9" r="9" fill="%23D9D9D9" fill-opacity="0.4"%3E%3C/circle%3E%3Cpath d="M9 13.5L5.10289 6.75L12.8971 6.75L9 13.5Z" fill="%23262626"%3E%3C/path%3E%3C/svg%3E');
  background-repeat: no-repeat;
  background-position: calc(100% + 2px) center;
  background-size: 18px;
}

input[type="date"]:focus::-webkit-calendar-picker-indicator,
input[type="time"]:focus::-webkit-calendar-picker-indicator {
  transform: rotate(-90deg);
}

/* UGC MODAL POPUP */
.ugc_modal_wrapper .modal-content {
  width: 100%;
  max-width: 328px;
  margin: auto;
}

.ugc_modal {
  padding: 5px 22px 33px 22px;
}

.ugc_modal h2 {
  font-size: 16px;
  line-height: 21.79px;
  color: var(--text-dark);
  margin-bottom: 7px;
}

.ugc_modal p {
  font-size: 10px;
  font-family: var(--font-family-sans);
  line-height: 13.62px;
}

.ugc_modal div {
  margin-top: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.ugc_modal div .btn {
  border-radius: 30px;
  font-size: 16px;
  line-height: 21.79px;
  font-family: var(--font-family-sans);
  font-weight: var(--font-weight-400);
  padding: 9.5px 32.5px;
}

.ugc_modal div .btn:first-child {
  background-color: var(--bg-secondary-subtle);
  color: var(--text-light-gray-9);
}

.ugc_modal div .btn:last-child {
  background-color: var(--bg-tomato);
  color: var(--text-white);
}

@media screen and (max-width: 576px) {
  .react-datepicker-popper {
    left: 30px !important;
  }
}
